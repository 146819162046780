'use strict';

require('jquery');
require('jquery-ui');
require('jquery-base64');
require('bootstrap-sass');
require('bootstrap-sass/assets/javascripts/bootstrap.js');
require('angular');
require('angular-route');
require('angular-resource');
require('angular-cookies');
require('angular-sanitize');
require('angular-animate');
require('angular-touch');
require('angular-ui-router');
require('angular-bootstrap');
require('moment');
require('font-awesome/css/font-awesome.min.css');
require('../scss/auth.scss');
require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/404.html");

require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/code/admin_login.html");

require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/code/password/account-activation.html");

require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/code/password/password-reset-request.html");

require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/code/password/password-reset.html");

require("imports-loader?angular!/opt/clinicaid/development/frontend/login/app/code/two_factor_authentication.html");


angular.module('clinicaidAdminLoginApp',
	[
		'ngRoute',
		'ui.router'])
	.config(
	[
		'$urlMatcherFactoryProvider',
		'$urlRouterProvider',
		'$httpProvider',
		'$stateProvider',
		'$locationProvider',
		function(
			$urlMatcherFactoryProvider,
			$urlRouterProvider,
			$httpProvider,
			$stateProvider,
			$locationProvider)
		{
			$locationProvider.hashPrefix('');
			$locationProvider.html5Mode(true);

			$urlMatcherFactoryProvider.strictMode(false);

			$urlRouterProvider.otherwise('/');

			$stateProvider.state('/', {
				url: "/",
				views: {
					'main_window': {
						templateUrl: 'code/admin_login.html',
						controller: 'AdminLoginController'
					}
				}
			}).state('two-factor-authentication', {
				url: "/two-factor-authentication",
				views: {
					'main_window': {
						templateUrl: 'code/two_factor_authentication.html',
						controller: 'AdminTwoFactorAuthenticationLoginController'
					}
				},
				params: {
					admin_user_uuid: null,
					admin_user_email: null
				}
			})
		}]);
