/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<script src=\"https://accounts.google.com/gsi/client\" async defer=\"defer\"></script>\n<div id=\"g_id_onload\" data-client_id=\"197608336513-tru85o5vs4bnd3f8nhfbpu02847igo2t.apps.googleusercontent.com\" data-callback=\"on_sign_in\">\n</div>\n<div ng-show=\"show_logging_in\">\n<div class=\"container segment login_box\">\n<div class=\"row-fluid\">\n<div class=\"progress progress-striped active\">\n<div class=\"bar\" style=\"width: 100%;\"></div>\n</div>\n<div class=\"login-inner centertext\">\nSending two factor authentication code\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n</div>\n<div ng-hide=\"show_logging_in\">\n<div class=\"container segment login_box\">\n<div class=\"row-fluid\">\n<div class=\"alert alert-info\" ng-show=\"continue_message\">\n{{continue_message}}\n</div>\n<div class=\"login-form span6 offset3\">\n<div>\n<legend>Admin Login</legend>\n<div class=\"alert alert-error login-error\" ng-cloak ng-show=\"show_errors\">\nError logging in:\n<div ng-repeat=\"error in login_errors.standard_errors\">\n<span>{{error}}</span>\n</div>\n</div>\n<div class=\"g_id_signin\" data-type=\"standard\" data-shape=\"circle\"></div>\n</div>\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n</div>\n";
var id1="code/admin_login.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
