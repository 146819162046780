'use strict';

angular.module('clinicaidAdminLoginApp').controller(
	'AdminLoginController',
	[
		'$scope',
		'$http',
		'$state',
	function ($scope, $http, $state)
	{
		const google_oauth_post_url = "/services/auth/admin/login/google_oauth_authentication/"

		$scope.login_errors = null;
		$scope.show_errors = false;
		$scope.show_logging_in = false;

		$scope.continue_message = null;
		if(Clinicaid.Util.exists(window.location.hash))
		{
			$scope.continue_message = "Please log in to continue";
		}

		$scope.on_sign_in = function on_sign_in(credential_response) {
			var id_token = credential_response.credential;

			var post_data= {
				id_token: id_token
			};

			// Use the id_token for server side verification.
			$http.post(google_oauth_post_url, post_data).then(
				function success(result)
				{
					var data = result.data;

					if (data.result === "error")
					{
						$scope.login_errors = data.errors;
						$scope.show_errors = true;
					}
					else if (data.result === 'success')
					{
						$scope.show_logging_in = true;

						Clinicaid.Util.save_admin_login_cookie(data.token);

						// if user requested a restricted URL and was forced to login, use the hash to forward
						// to the requested URL after login; otherwise defaults to dashboard
						window.location = Clinicaid.Util.get_clinicaid_admin_url(window.location.hash);
					}
				},
				function error()
				{
					$scope.login_errors = { 'System Error': [ 'Could not connect to login server.' ] };
					$scope.show_errors = true;
				}
			);
		};
		window.on_sign_in = $scope.on_sign_in;

		$scope.submit_login = function()
		{
			// EWWWW: directly access the DOM elements for the username and password
			//        because the browser autocomplete doesn't trigger AngularJS's
			//        model update.
			$scope.username = $("#inputUsername").val();
			$scope.password = $("#inputPassword").val();

			$scope.show_logging_in = true;

			$http.post(
				'/services/auth/admin/login/',
				{ 'username': $scope.username, 'password': $scope.password }).then(
				function success(result)
				{
					var data = result.data;
					console.log("Login response data %o", result.data)
					if (data.result === "error")
					{
						$scope.login_errors = data.errors;
						$scope.show_errors = true;
						$scope.show_logging_in = false;
					}
					else if (data.result === 'success')
					{
						// If we get a token here, a system user logged in. So don't require the authentication code
						// This is a temporary work around for system users until we set up an external rest service for them
						if (data.token)
						{
							$scope.show_logging_in = false;

							Clinicaid.Util.save_admin_login_cookie(data.token);

							// if user requested a restricted URL and was forced to login, use the hash to forward
							// to the requested URL after login; otherwise defaults to dashboard
							window.location = Clinicaid.Util.get_clinicaid_admin_url(window.location.hash);
						}
						else
						{
							$scope.show_logging_in = false;
							$state.go('two-factor-authentication', {admin_user_uuid: data.admin_user_uuid, admin_user_email: data.admin_user_email});
						}
					}
				},
				function error()
				{
					$scope.login_errors = { 'System Error': [ 'Could not connect to login server.' ] };
					$scope.show_errors = true;
					$scope.show_logging_in = false;
				}
			);
		}
	}]);
