/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div ng-show=\"show_logging_in\">\n<div class=\"container segment login_box\">\n<div class=\"row-fluid\">\n<div class=\"progress progress-striped active\">\n<div class=\"bar\" style=\"width: 100%;\"></div>\n</div>\n<div class=\"login-inner centertext\">\nLogging in\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n</div>\n<div class=\"container segment login_box\" ng-hide=\"show_logging_in\">\n<div class=\"row-fluid\">\n<div class=\"alert alert-danger\" ng-show=\"show_errors\">\n<div ng-repeat=\"error in error_message.standard_errors\">\n<span>{{error}}</span>\n</div>\n</div>\n<div ng-show=\"sending_code\">\n<div class=\"container\">\n<div class=\"row-fluid\">\n<div class=\"login-inner centertext\">\nResending authentication code to {{admin_user_email}}\n</div>\n<div class=\"progress progress-striped active\">\n<div class=\"bar\" style=\"width: 100%;\"></div>\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n</div>\n<div class=\"alert alert-success\" ng-show=\"auth_code_resent\">\nSuccessfully resent authentication code to: {{admin_user_email}}\n</div>\n<h5 class=\"instructions\">\nEnter the code that was sent to: {{admin_user_email}}\n</h5>\n<div class=\"login-form\">\n<form name=\"login_form\" class=\"form-horizontal\" ng-submit=\"submit_login()\">\n<div class=\"control-group\">\n<label class=\"control-label\" for=\"input-code\">Code:</label>\n<div class=\"controls\">\n<div class=\"input-prepend\">\n<input type=\"code\" id=\"input-code\" placeholder=\"Authentication Code\" ng-model=\"otp_code\" required autofocus autocomplete=\"off\"/>\n</div>\n</div>\n</div>\n<div class=\"form_actions\">\n<span class=\"buttons\">\n<button class=\"button blue\" type=\"submit\">\nAuthenticate<span class=\"bar\">&nbsp;</span>\n</button>\n</span>\n</div>\n</form>\n<a href=\"#\" class=\"pull-left\" ng-click=\"$event.preventDefault();resend_auth_code()\">Resend authentication code</a>\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n";
var id1="code/two_factor_authentication.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
