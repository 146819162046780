'use strict';

angular.module('clinicaidLoginApp').controller(
	'LoginController',
	[
		'$scope',
		'$http',
	function ($scope, $http)
	{
		$scope.login_errors = null;
		$scope.show_errors = false;
		$scope.show_logging_in = false;

		$scope.continue_message = null;
		if(Clinicaid.Util.exists(window.location.hash))
		{
			$scope.continue_message = "Please log in to continue";
			if(window.location.hash.startsWith('#/email-verification/'))
			{
				$scope.continue_message += ' and confirm your email address';
			}
		}

		$scope.submit_login = function()
		{
			// EWWWW: directly access the DOM elements for the username and password
			//        because the browser autocomplete doesn't trigger AngularJS's
			//        model update.
			$scope.email = $("#input-email").val();
			$scope.password = $("#inputPassword").val();

			$http.post(
				'/auth/active_user/login/',
				{ 'email': $scope.email, 'password': $scope.password }).then(
				function success(result)
				{
					var data = result.data;

					if (data.result === "error")
					{
						$scope.login_errors = data.errors;
						$scope.show_errors = true;
					}
					else if (data.result === 'success')
					{
						$scope.show_logging_in = true;

						Clinicaid.Util.save_login_cookie(data.token);

						// if user requested a restricted URL and was forced to login, use the hash to forward
						// to the requested URL after login; otherwise defaults to dashboard
						window.location = Clinicaid.Util.get_clinicaid_app_url(window.location.hash);
					}
				},
				function error()
				{
					$scope.login_errors = { 'System Error': [ 'Could not connect to login server.' ] };
					$scope.show_errors = true;
				}
			);
		}
	}]);
