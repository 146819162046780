/*** IMPORTS FROM imports-loader ***/
var angular = require("angular");

var angular=window.angular,ngModule;
try {ngModule=angular.module(["ng"])}
catch(e){ngModule=angular.module("ng",[])}
var v1="<div class=\"container segment login_box\" ng-controller=\"PasswordResetRequestController as prr_controller\" ng-show=\"!processing\">\n<div class=\"row-fluid\" ng-show=\"prr_controller.reset_password_sent\">\n<div class=\"alert alert-success\">\nPlease check your email for a link to reset your password.\n</div>\n</div>\n<div class=\"row-fluid\" ng-show=\"!prr_controller.reset_password_sent\">\n<p class=\"instructions\">\nForgot your ClinicAid password? Enter your email address and we will send you a link to reset your password.\n</p>\n<div class=\"alert alert-danger\" ng-show=\"prr_controller.error_message\">\n{{prr_controller.error_message}}\n</div>\n<div class=\"login-form span6 offset3\">\n<form name=\"login_form\" class=\"form-horizontal\" ng-submit=\"prr_controller.request_password_reset()\">\n<div class=\"control-group\">\n<label class=\"control-label\" for=\"input-email\">Email Address</label>\n<div class=\"controls\">\n<div class=\"input-prepend\">\n<span class=\"add-on\"><i class=\"fa fa-envelope\"></i></span>\n<input type=\"email\" id=\"input-email\" placeholder=\"Email\" ng-model=\"prr_controller.email\" required autofocus autocomplete=\"off\"/>\n</div>\n</div>\n</div>\n<div class=\"form_actions\">\n<span class=\"buttons\">\n<button class=\"button blue\">\nRequest Password Reset<span class=\"bar\">&nbsp;</span>\n</button>\n</span>\n</div>\n</form>\n</div>\n<div class=\"clear\"></div>\n</div>\n</div>\n";
var id1="code/password/password-reset-request.html";
var inj=angular.element(window.document).injector();
if(inj){inj.get("$templateCache").put(id1,v1);}
else{ngModule.run(["$templateCache",function(c){c.put(id1,v1)}]);}
module.exports=v1;
