'use strict';

angular.module('clinicaidPasswordApp').controller(
	'PasswordResetRequestController',
	[
		'$http',
	function ($http)
{
	var controller = {};

	controller.email = null;
	controller.error_message = null;
	controller.reset_password_sent = false;

	controller.request_password_reset = function request_password_reset()
	{
		controller.error_message = null;

		if(!Clinicaid.Util.exists(controller.email))
		{
			controller.error_message = "Email is required";
			return;
		}

		controller.processing = true;

		$http.post(
			'/auth/password-reset', { 'email': controller.email }).then(
			function success(response)
			{
				var results = response.data;
				if(results && results.result == 'success')
				{
					controller.reset_password_sent = true;
				}
				else
				{
					controller.error_message =
						"This email address has not been verified or isn't associated with an account. " +
						"Please contact support@clinicaid.ca";
				}

				controller.processing = false;
			},
			function error()
			{
				controller.error_message =
					"An error has occurred. Please contact support@clinicaid.ca";
				controller.processing = false;
			}
		);
	};

	return controller;
}]);
