'use strict';

window.Clinicaid = window.Clinicaid || {};

window.Clinicaid.Util = {};

Clinicaid.Util.exists = function exists(value)
{
	return !(value === null || value === undefined || value === '');
};

Clinicaid.Util.save_login_cookie = function save_login_cookie(token_value)
{
	Clinicaid.Util._save_cookie('token', token_value);
};

Clinicaid.Util.get_clinicaid_app_url = function get_clinicaid_app_url(forward_to_hash)
{
	return Clinicaid.Util._get_app_url('', '#/dashboard', forward_to_hash);
};

Clinicaid.Util.save_admin_login_cookie = function save_admin_login_cookie(token_value)
{
	Clinicaid.Util._save_cookie('admin_token', token_value);
};

Clinicaid.Util.get_clinicaid_admin_url = function get_clinicaid_admin_url(forward_to_hash)
{
	return Clinicaid.Util._get_app_url('/admin', '#/accounts', forward_to_hash);
};

Clinicaid.Util._get_app_url = function _get_app_url(base_path, default_hash, forward_to_hash)
{
	var hostname = window.location.hostname;
	if (window.location.port && window.location.port != 80)
	{
		hostname = hostname + ':' + window.location.port;
	}

	// redirecting to the same domain and the same protocol didn't used to work in
	// safari but now it seems to work fine
	var url = window.location.protocol + "//" + hostname + base_path + "/";

	if (forward_to_hash)
	{
		url += forward_to_hash;
	}
	else
	{
		url += default_hash;
	}

	return url;
};

Clinicaid.Util._save_cookie = function _save_cookie(key, value)
{
	var expire_date = new Date();
	expire_date.setDate(expire_date.getDate() + 1);
	document.cookie = key + "=" + value + ';expires=' + expire_date.toUTCString() + ';path=/;secure';
};
