'use strict';

angular.module('clinicaidAdminLoginApp').controller(
	'AdminTwoFactorAuthenticationLoginController',
	[
		'$scope',
		'$http',
		'$stateParams',
		function ($scope, $http, $stateParams)
		{
			$scope.login_errors = null;
			$scope.show_errors = false;
			$scope.show_logging_in = false;
			$scope.auth_code_resent = false;
			$scope.sending_code = false;
			$scope.admin_user_uuid = $stateParams.admin_user_uuid;
			$scope.admin_user_email = $stateParams.admin_user_email;

			$scope.resend_auth_code = function resend_auth_code()
			{
				$scope.show_errors = false;
				$scope.auth_code_resent = false;
				$scope.sending_code = true;

				var post_data = {
					admin_user_uuid: $scope.admin_user_uuid
				};

				$http.post('/services/auth/admin/login/resend_authentication_code/', post_data).then(
					function success(result)
					{
						var data = result.data;

						if (data.result === "error")
						{
							$scope.error_message = data.errors;
							$scope.show_errors = true;
							$scope.sending_code = false;
						}
						else if (data.result === 'success')
						{
							$scope.sending_code = false;
							$scope.auth_code_resent = true;
						}
					},
					function error()
					{
						$scope.login_errors = { 'System Error': [ 'Could not resend authentication code' ] };
						$scope.show_errors = true;
						$scope.sending_code = false;
					}
				);
			};

			$scope.submit_login = function submit_login()
			{
				$scope.show_errors = false;
				$scope.auth_code_resent = false;

				var post_data= {
					admin_user_uuid: $scope.admin_user_uuid,
					otp_code: $scope.otp_code
				};

				$http.post('/services/auth/admin/login/two_factor_authentication/', post_data).then(
					function success(result)
					{
						var data = result.data;

						if (data.result == "error")
						{
							$scope.error_message = data.errors;
							$scope.show_errors = true;
						}
						else if (data.result == 'success')
						{
							$scope.show_logging_in = true;

							Clinicaid.Util.save_admin_login_cookie(data.token);

							// if user requested a restricted URL and was forced to login, use the hash to forward
							// to the requested URL after login; otherwise defaults to dashboard
							window.location = Clinicaid.Util.get_clinicaid_admin_url(window.location.hash);
						}
					},
					function error()
					{
						$scope.login_errors = { 'System Error': [ 'Could not connect to login server.' ] };
						$scope.show_errors = true;
					}
				);
			}
		}]);
