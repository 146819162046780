'use strict';

angular.module('clinicaidPasswordApp').controller(
	'AccountActivationController',
	[
		'$http',
		'$stateParams',
		'$timeout',
	function ($http, $stateParams, $timeout)
	{
		var controller = {};

		controller.email = null;
		controller.password = null;
		controller.confirm_password = null;

		controller.error_message = null;

		controller.loading = true;
		controller.confirmed_token = false;
		controller.processing = false;
		controller.show_logging_in = false;

		controller.init = function init()
		{
			controller.loading = true;

			controller.token = $stateParams.token;
			$http.get('/auth/account-activation/' + encodeURIComponent(controller.token)).then(
				function success(response)
				{
					var results = response.data;

					if(results && results.result == 'success' && results.data.email)
					{
						controller.email = results.data.email;
						controller.confirmed_token = true;
					}

					controller.loading = false;
				},
				function error()
				{
					controller.loading = false;
				}
			);
		};

		controller.activate_account = function activate_account()
		{
			controller.error_message = null;

			if(!Clinicaid.Util.exists(controller.password))
			{
				controller.error_message = "New Password is required";
				return;
			}

			if(controller.password != controller.confirm_password)
			{
				controller.error_message = "Passwords do not match";
				return;
			}

			controller.processing = true;

			var post_data = {
				password: controller.password,
				confirm_password: controller.confirm_password
			};

			controller.password = null;
			controller.confirm_password = null;

			var url = '/auth/account-activation/' + encodeURIComponent(controller.token);
			$http.post(url, post_data).then(
				function success(response)
				{
					var results = response.data;
					
					if(results && results.result == 'success')
					{
						// save the login cookie and forward to the app
						controller.show_logging_in = true;
						$timeout(function()
						{
							Clinicaid.Util.save_login_cookie(results.data.token);
							window.location = Clinicaid.Util.get_clinicaid_app_url();
						}, 1000);
					}
					else if(results && results.result == 'error' && results.errors.standard_errors.length > 0)
					{
						controller.error_message = results.errors.standard_errors[0];
					}
					else if(results && results.result == 'error' && results.errors.field_errors['password'])
					{
						controller.error_message = 'Password ' + results.errors.field_errors['password'];
					}
					else
					{
						controller.error_message =
							"An error has occurred. Please contact support@clinicaid.ca";
					}

					controller.processing = false;
				},
				function error()
				{
					controller.error_message =
						"An error has occurred. Please contact support@clinicaid.ca";
					controller.processing = false;
				}
			);
		};

		return controller;
	}]);
